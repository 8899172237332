import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation,useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { Box, Button, Typography } from "@material-ui/core"

import Error404Page from "../errors/Error404Page"
import { FirstStep } from "../../components/consultationRating/FirstStep"
import RatingTopBar from "../../components/consultationRating/RatingTopBar"
import { SurveyLocked } from "../../components/consultationRating/SurveyLocked"
import { SurveyNotFound } from "../../components/consultationRating/SurveyNotFound"
import { ThankYouStep } from "../../components/consultationRating/ThankYouStep"
import { SurveyDataHook } from "./hooks/types"
import { useStyles } from "./ConsultationRatingPage.styles"

import { SurveyDataContext, useSurveyDataContext } from "./hooks/SurveyDataContext"
import { useSurveyData as apiUseSurveyData } from "./hooks/useSurveyData"
import { useSurveyData as defaultUseSurveyData } from "./hooks/useSurveyDataInMemory"

interface ConsultationRatingPageProps {
  useSurveyData?: (ratingCode: string, options: { initialRating?: number; initialNotHappened?: boolean }) => SurveyDataHook
}

const ConsultationRatingPage: React.FC<ConsultationRatingPageProps> = ({
  useSurveyData = apiUseSurveyData
}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { ratingCode } = useParams<{ ratingCode: string }>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  // Pobieramy parametry z URL
  const initialRating = parseInt(searchParams.get("rating") || "0", 10)
  const initialNotHappened = searchParams.get("notHappened") === "true"

  const [data, setData] = React.useState<SurveyDataHook["data"] | null>(null)
  const [error, setError] = React.useState<SurveyDataHook["error"] | null>(null)
  const [isInitialLoading, setIsInitialLoading] = React.useState(true)
  const [formData, setFormData] = React.useState({
    rating5: 0,
    rating10: 0,
    note: "",
    visitNotHappened: false
  })
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const lastNoteUpdateRef = React.useRef<string>("")
  const updateTimeoutRef = React.useRef<NodeJS.Timeout>()
  const initialActionPerformed = React.useRef(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const abortControllerRef = React.useRef<AbortController>()

  const surveyData = useSurveyData(ratingCode)
  const { data: surveyDataData, error: surveyDataError, fetchData, updateSurvey, markAsNotHappened, completeSurvey } = surveyData

  React.useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      fetchData()

      // Tutaj możemy wykonać inicjalne akcje
      if (initialNotHappened) {
        handleVisitNotHappened()
      } else if (initialRating > 0) {
        handleRatingChange(initialRating)
      }
    }, 250)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  React.useEffect(() => {
    if (surveyDataData !== null || surveyDataError) {
      setIsInitialLoading(false)
    }
  }, [surveyDataData, surveyDataError])

  React.useEffect(() => {
    if (surveyDataData?.status === "completed") {
      setIsSubmitted(true)
    }
  }, [surveyDataData?.status])

  const handleRatingChange = async (rating: number) => {
    await updateSurvey({
      consultationRating: rating,
      notHappened: false
    })
    setFormData(prev => ({
      ...prev,
      rating5: rating,
      visitNotHappened: false
    }))
  }

  const handleVisitNotHappened = async () => {
    await updateSurvey({
      notHappened: true,
      consultationRating: 0
    })
    setFormData(prev => ({
      ...prev,
      visitNotHappened: true,
      rating5: 0
    }))
  }

  const handleSubmitNotHappened = async () => {
    await markAsNotHappened()
    setIsSubmitted(true)
  }

  const handleRating10Change = async (rating: number) => {
    await updateSurvey({
      consultationRating: formData.rating5,
      recommendationRating: rating,
      note: formData.note,
      notHappened: false
    })
    setFormData(prev => ({ ...prev, rating10: rating }))
  }

  const handleNoteChange = (note: string) => {
    setFormData(prev => ({ ...prev, note }))

    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current)
    }

    updateTimeoutRef.current = setTimeout(() => {
      if (note !== lastNoteUpdateRef.current) {
        updateSurvey({
          consultationRating: formData.rating5,
          recommendationRating: formData.rating10,
          note,
          notHappened: false
        }).catch(console.error)
        lastNoteUpdateRef.current = note
      }
    }, 1000)
  }

  const handleSubmit = async () => {
    await completeSurvey({
      consultationRating: formData.rating5,
      recommendationRating: formData.rating10,
      note: formData.note,
      notHappened: false
    })
    setIsSubmitted(true)
  }

  if (isSubmitted) {
    return (
      <Box className={classes.root}>
        <ThankYouStep />
      </Box>
    )
  }

  if (isInitialLoading) {
    return null
  }

  if (surveyDataError?.status === 404) {
    return <Error404Page />
  }

  if (surveyDataError) {
    return (
      <Box className={classes.root}>
        <Typography
          variant="h5"
          component="span"
          color="error"
        >
          {t("errors:defaultError")}
        </Typography>
      </Box>
    )
  }

  if (!surveyDataData) {
    return null
  }

  if (surveyDataData.status === "lock") {
    return (
      <Box className={classes.root}>
        <SurveyLocked />
      </Box>
    )
  }

  if (surveyDataData.status !== "active") {
    return null
  }

  return (
    <>
      <RatingTopBar showLogo={false} />
      <Box className={classes.root}>
        <FirstStep
          data={surveyDataData}
          rating={formData.rating5}
          rating10={formData.rating10}
          note={formData.note}
          onRatingChange={handleRatingChange}
          onRating10Change={handleRating10Change}
          onNoteChange={handleNoteChange}
          onVisitNotHappened={handleVisitNotHappened}
          onSubmitNotHappened={handleSubmitNotHappened}
          visitNotHappened={formData.visitNotHappened}
          onSubmit={handleSubmit}
          onSubmitSuccess={() => setIsSubmitted(true)}
        />
      </Box>
    </>
  )
}

export default ConsultationRatingPage
