import React, { FC, useEffect, useRef, useState } from "react"
import { useLocation,useParams } from "react-router-dom"
import { Box } from "@material-ui/core"

import api from "../../api/api"
import { getConsultationBaseInfoConfig, postConsultationChatRatingV1Config } from "../../api/routes"
import useConsultationLang from "../../hooks/useConsultationLang"
import ConsultationRatingMessage from "../../components/consultationChat/consultationRatingModal/ConsultationRatingMessage.component"
import ConsultationRatingModal from "../../components/consultationChat/consultationRatingModal/ConsultationRatingModal.component"
import { redirectToError500Page } from "../../utils/handleErrors"
import { useStyles } from "./ConsultationRatingPage.styles"

interface ConsultationBaseInfo {
  visitAt: string;
  doctor: {
    firstName: string;
    lastName: string;
  };
  isRated: boolean;
}

interface ConsultationRatingModalPageParams {
  magicLinkHash: string;
}

const TWO_WEEKS_IN_MS = 14 * 24 * 60 * 60 * 1000 // 14 dni w milisekundach

const ConsultationRatingModalPage: FC = () => {
  const classes = useStyles()
  const consultationLang = useConsultationLang()
  const { magicLinkHash } = useParams<ConsultationRatingModalPageParams>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [initialRating, setInitialRating] = useState<number | null>(null)
  const [consultationData, setConsultationData] = useState<ConsultationBaseInfo | null>(null)
  const [loading, setLoading] = useState(true)
  const [isExpired, setIsExpired] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [hasRated, setHasRated] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  // Efekt do obsługi parametru rating
  useEffect(() => {
    const ratingParam = searchParams.get("rating")
    const parsedRating = ratingParam ? parseInt(ratingParam, 5) : null
    setInitialRating(parsedRating)
  }, [location.search])

  useEffect(() => {
    const fetchConsultationData = async () => {
      try {

        const response = await api.request(getConsultationBaseInfoConfig(magicLinkHash))

        setConsultationData(response.data)

        const visitDate = new Date(response.data.visitAt)
        const now = new Date()
        const timeDiff = now.getTime() - visitDate.getTime()
        const isExpiredValue = timeDiff > TWO_WEEKS_IN_MS

        setIsExpired(isExpiredValue)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
        redirectToError500Page(error)
      }
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      fetchConsultationData()
    }, 250)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [magicLinkHash])

  const handleClose = () => {
    setShowModal(false)
  }

  const handleRatingSuccess = () => {
    setHasRated(true)
  }

  if (loading || !consultationData || !showModal) {
    return null
  }

  const doctorName = `${consultationData.doctor.firstName} ${consultationData.doctor.lastName}`

  if (isExpired) {
    return (
      <ConsultationRatingMessage
        message={consultationLang.getLabel("chatConsultation:rating:expired")}
        onClose={handleClose}
      />
    )
  }

  if (hasRated || consultationData.isRated) {
    return (
      <ConsultationRatingMessage
        message={consultationLang.getLabel("chatConsultation:rating:alreadyRated")}
        onClose={handleClose}
      />
    )
  }

  return (
    <Box className={classes.root}>
      <ConsultationRatingModal
        consultationId={magicLinkHash}
        doctorName={doctorName}
        rate={null}
        initialRate={initialRating}
        onRatingSuccess={handleRatingSuccess}
        title={consultationLang.getLabel("chatConsultation:rating:rateYourVisit")}
        hideCloseButtons={true}
        ratingRouteConfig={postConsultationChatRatingV1Config}
      />
    </Box>
  )
}

export default ConsultationRatingModalPage
