import React from "react"

import api from "../../../api/api"
import {
  completeRatingVisitSurveyConfig,
  getRatingVisitSurveyConfig,
  markRatingVisitAsNotHappenedConfig,
  updateRatingVisitSurveyConfig} from "../../../api/routes"
import { ConsultationRatingData, SurveyDataHook, SurveyUpdateData } from "./types"

interface SurveyError {
  status: number
  message?: string
}

export const useSurveyData = (ratingCode: string): SurveyDataHook & { fetchData: () => Promise<void> } => {
  const [data, setData] = React.useState<ConsultationRatingData | null>(null)
  const [error, setError] = React.useState<SurveyError | null>(null)
  const isMountedRef = React.useRef(true)

  React.useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const fetchData = async () => {
    try {
      const response = await api.request(getRatingVisitSurveyConfig(ratingCode))

      if (!isMountedRef.current) return

      if (response.status !== 200) {
        setError({ status: response.status })
        return
      }

      setData(response.data)
      setError(null)
    } catch (err) {
      if (!isMountedRef.current) return
      console.error("Error fetching survey data:", err)
      if (err.response?.status) {
        setError({ status: err.response.status })
      } else {
        setError({ status: 500 })
      }
    }
  }

  const updateSurvey = async (updateData: SurveyUpdateData): Promise<void> => {
    const response = await api.request({
      ...updateRatingVisitSurveyConfig(ratingCode),
      data: updateData
    })

    if (response.status !== 200) {
      throw new Error("Survey update failed")
    }
  }

  const markAsNotHappened = async (): Promise<void> => {
    const response = await api.request(markRatingVisitAsNotHappenedConfig(ratingCode))

    if (response.status === 200) {
      setData(prev => prev ? { ...prev, status: "completed" } : prev)
    }
  }

  const completeSurvey = async (updateData: SurveyUpdateData): Promise<void> => {
    const response = await api.request({
      ...completeRatingVisitSurveyConfig(ratingCode),
      data: updateData
    })

    if (response.status === 200) {
      setData(prev => prev ? { ...prev, status: "completed" } : prev)
    }
  }

  return {
    data,
    error,
    fetchData,
    updateSurvey,
    markAsNotHappened,
    completeSurvey
  }
}
