import { makeStyles, Theme } from "@material-ui/core"

import { TOP_BAR_HEIGHT_SPACING } from "../../components/topBar/TopBar.styles"

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(13),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: theme.spacing(2),
    },
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
    position: "relative"
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  loadingContainer: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[50]
  },
  lockContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[50]
  },
  lockCard: {
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(4)
  },
  lockText: {
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  mainCard: {
    // ... istniejące style ...
  }
}))
